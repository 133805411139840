<template>
   <div class="grid">
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <div class="col-12">
         <div class="card">
            <Toolbar class="mb-4">
               <template #start>
                  <Button
                     label="Add"
                     icon="pi pi-plus"
                     class="p-button-success mr-2"
                     @click="addSpecialist"
                  />
               </template>
            </Toolbar>
            <div>
               <DataTable
                  ref="dt"
                  :value="specialists"
                  dataKey="id"
                  :paginator="true"
                  :rows="10"
                  :filters="filters"
                  :loading="loading"
                  responsiveLayout="scroll"
               >
                  <template #header>
                     <div
                        class="
                           table-header
                           p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                        "
                     >
                        <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                           Manage Specialist
                        </h5>
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters['global'].value"
                              placeholder="Search..."
                           />
                        </span>
                     </div>
                  </template>

                  <Column
                     field="id"
                     header="ID"
                     style="min-width: 12rem"
                  ></Column>
                  <Column
                     field="specialist"
                     header="Specialist"
                     style="min-width: 16rem"
                  ></Column>
                  <Column :exportable="false" style="min-width: 8rem">
                     <template #body="{ data }">
                        <Button
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-success p-mr-2"
                           @click="editSpecialist(data)"
                        />
                        <Button
                           icon="pi pi-trash"
                           class="p-button-rounded p-button-warning"
                           @click="confirmDeleteSpecialist(data.id)"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>

            <Dialog
               v-model:visible="specialistDialog"
               :style="{ width: '450px' }"
               header="Spcialist Details"
               :modal="true"
               class="p-fluid"
            >
               <div class="p-field">
                  <label for="name">Name</label>
                  <InputText
                     id="name"
                     v-model="input.specialist"
                     required="true"
                     autofocus
                  />
                  <v-errors
                     :serverErrors="errorFor('specialist')"
                     :vuelidateErrors="{
                        errors: v$.input.specialist.$errors,
                        value: 'Specialist Name',
                     }"
                  ></v-errors>
               </div>

               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     class="p-button-text"
                     @click="hideDialog"
                  />
                  <Button
                     label="Save"
                     icon="pi pi-check"
                     class="p-button-text"
                     @click="saveUpdatedSpecialist"
                  />
               </template>
            </Dialog>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
   name: "Specialist",
   setup() {
      return { v$: useVuelidate() };
   },
   mixins: [validationErrors],

   data() {
      return {
         specialists: null, //lists array
         input: {},
         filters: {},
         specialistDialog: false,
         loading: true,
      };
   },

   validations() {
      return {
         input: {
            specialist: { required },
         },
      };
   },

   methods: {
      ...mapActions([
         "specialist/fetchAllSpecalists",
         "specialist/update",
         "specialist/delete",
         "specialist/create",
      ]),
      fethAllSpecialists() {
         this["specialist/fetchAllSpecalists"]()
            .then((response) => {
               this.specialists = response;
               this.loading = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      initFilters() {
         this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
         };
      },
      addSpecialist() {
         this.input = {};
         this.specialistDialog = true;
      },
      editSpecialist(specialist) {
         this.input = { ...specialist };
         this.specialistDialog = true;
      },
      async saveUpdatedSpecialist() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         const inputData = this.input;
         console.log(inputData);
         if (inputData.id) {
            this["specialist/update"](inputData)
               .then(() => {
                  this.loading = true;
                  this.specialistDialog = false;
                  this.fethAllSpecialists();

                  this.$toast.add({
                     severity: "success",
                     summary: "Confirmed",
                     detail: "Successfully updated.",
                     life: 3000,
                  });
               })
               .catch((error) => {
                  console.log(error);
               });
         } else {
            this["specialist/create"](inputData)
               .then(() => {
                  this.loading = true;
                  this.specialistDialog = false;
                  this.fethAllSpecialists();
                  this.$toast.add({
                     severity: "success",
                     summary: "Confirmed",
                     detail: "Successfully created.",
                     life: 3000,
                  });
               })
               .catch((error) => {
                  console.log(error);
               });
         }
      },
      confirmDeleteSpecialist(id) {
         this.$confirm.require({
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Successfully deleted.",
                  life: 3000,
               });
               this["specialist/delete"](id)
                  .then(() => {
                     this.loading = true;
                     this.fethAllSpecialists();
                     console.log(id);
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
      hideDialog() {
         this.specialistDialog = false;
      },
   },
   created() {
      this.fethAllSpecialists();
      this.initFilters();
   },
};
</script>

<style lang="scss" scoped>
.table-header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 960px) {
      align-items: start;
   }
}
</style>
